import request from '@/utils/request'


// 查询动态图谱定制列表
export function listGraphCustomized(query) {
    return request({
        url: '/dynamic/dynamic-graph-customized/list',
        method: 'get',
        params: query
    })
}

// 查询动态图谱定制分页
export function pageGraphCustomized(query) {
    return request({
        url: '/dynamic/dynamic-graph-customized/page',
        method: 'get',
        params: query
    })
}

// 查询动态图谱定制详细
export function getGraphCustomized(data) {
    return request({
        url: '/dynamic/dynamic-graph-customized/detail',
        method: 'get',
        params: data
    })
}

// 新增动态图谱定制
export function addGraphCustomized(data) {
    return request({
        url: '/dynamic/dynamic-graph-customized/add',
        method: 'post',
        data: data
    })
}

// 修改动态图谱定制
export function updateGraphCustomized(data) {
    return request({
        url: '/dynamic/dynamic-graph-customized/edit',
        method: 'post',
        data: data
    })
}

// 删除动态图谱定制
export function delGraphCustomized(data) {
    return request({
        url: '/dynamic/dynamic-graph-customized/delete',
        method: 'post',
        data: data
    })
}

// 修改动态图谱定制
export function approveGraphCustomized(data) {
    return request({
        url: '/dynamic/dynamic-graph-customized/approve',
        method: 'post',
        data: data
    })
}
